export default {
  app: "diner-app",
  common: {
    loading: "加载中...", // Loading...
    unexpectedError: "发生意外错误", // An unexpected error occurred
    confirm: "确认", // Confirm
    form: {
      required: "必需的", // Required
    },
    quantity: "x{{quantity}}",
    orBreak: "或", // or
  },
  navbar: {
    faq: "常见问题", // FAQ
    orderNow: "现在下单", // Order now
    qrOutletLabel: "从这里订购", // Ordering from
    address: {
      delivery: "选择外送地址", // Select a delivery address
      pickup: "选择自取地点", // Select pick up location
      default: "选择一个外送/自取地点", // Select a delivery/pickup location
    },
    pickupFrom: "从 {{labelForPickup}} 自取", // Pick up from {{labelForPickup}}
    timeslot: "选择日期和时间", // Select date and time
    signup: "注册", // Sign up
    login: "登录", // Log in
    logout: "登出", // Log out
    addressToolTip: "首先选择一个外送地址", // Select a delivery address first
    timeslotLabel: "选择一个时间段", // Select a time slot
    browseMenu: "浏览菜单", // Browse menu
    tableName: "桌号: {{ tableName }}", // Table: {{ tableName }}
  },
  block: {
    willOpenAtNextOpeningHours: {
      header: "看起来你来得有点早", // Looks like you’re a little early
      body: "我们的商店目前已关闭，将在 {{ openingTime }} 开放订单", // Our store is currently closed and will open for orders at {{ openingTime }}
    },
    outletCurrentlyClosed: {
      header: "哎呀，你来得有点晚了", // Whoops, you’re a little too late
      body: "我们商店的最后订单已结束。请在另一天回来，谢谢。", // Last orders for our store has ended. Please come back another day, thank you.
    },
    closingCountdown: "该商店即将关闭。请在 {{ closingTime }} 之前下订单", // This store is closing soon. Please place your orders by {{ closingTime }}
  },
  scroll: {
    top: "还原顶部", // TOP
  },
  account: {
    header: "账户", // Account
    navigation: {
      profile: "个人资料", // Profile
      orders: "订单", // Orders
      paymentMethods: "付款方式", // Payment Methods
      addresses: "地址", // Addresses
      referrals: "推荐", // Referrals
      promoCodes: "促销代码", // Promo codes
    },
    profile: {
      header: "个人资料", // Profile
      subheader: "由...提供", // Powered by
      description: "在下面管理您的个人资料", // Manage your profile below
      form: {
        labels: {
          title: "称呼", // Title
          name: "姓名", // Name
          email: "电子邮件", // Email
          mobileNumber: "手机号码", // Mobile number
          currentPassword: "当前密码", // Current password
          newPassword: "新密码", // New password
          newPasswordConfirmation: "新密码确认", // New password confirmation
        },
        update: "更新您的个人资料", // Update your profile
        success: {
          update: "个人资料已更新", // Profile updated
        },
      },
      rewards: {
        header: "奖励", // Rewards
        subheader: "您的奖励账户目前有 {{ count }} 点。", // You currently have {{ count }} points in your rewards account.
        profilePendingSync: "您的个人资料正在与 Advocado 同步。", // Your profile syncing with Advocado is in progress.
        note: "在 <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> 上登录以使用您的积分并管理您的账户。", // Log in on <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> to use your points and manage your account.
        profileOutOfSync:
          "您的个人资料现在与 Advocado 不同步。请登录到 <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> 以保持您的奖励账户更新。", // Your profile is now out of sync with Advocado. Please log in to <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> to keep your rewards account updated.
      },
    },
    orders: {
      header: "订单", // Orders
      subheader: "在一个地方跟踪您的即将到来和过去的订单。", // Keep track of your upcoming and past orders all in one place.
      upcomingOrders: "即将到来的订单", // Upcoming orders
      pastOrders: "过去的订单", // Past orders
      pagination:
        "第 {{ currentPage }} 页，共 {{ totalPages }} 页 ({{ count }} 订单)", // Page {{ currentPage }} of {{ totalPages }} ({{ count }} order)
      pagination_other:
        "第 {{ currentPage }} 页，共 {{ totalPages }} 页 ({{ count }} 订单)", // Page {{ currentPage }} of {{ totalPages }} ({{ count }} orders)
      pickupLabel: "在 {{labelForPickup}} 自取", // Pick up at {{labelForPickup}}
      dineInLabel: "在 {{labelForPickup}} 用餐", // Dine-in at {{labelForPickup}}
      fulfilledByLabel: "由 {{outlet}} 完成", // Fulfilled by {{outlet}}
      contactNumberLabel:
        "联系方式: <a class='underline text-default hover:text-default hover:underline' href='tel:{{contactNumberNoSpaces}}'>{{contactNumber}}</a>", // Contact: <a class='underline text-default hover:text-default hover:underline' href='tel:{{contactNumberNoSpaces}}'>{{contactNumber}}</a>
      servingDateLabel: "服务日期 {{ servingDate }}, 时间 {{ servingTime }}", // Serving on {{ servingDate }}, {{ servingTime }}
      servingDateCompletedLabel:
        "服务日期 {{ servingDate }}, 时间 {{ servingTime }}", // Served on {{ servingDate }}, {{ servingTime }}
      servingDate: "{{ servingDate }}, {{ servingTime }}", // {{ servingDate }}, {{ servingTime }}
      invoiceDate: "发票日期: {{ date }}", // Invoice date: {{ date }}
      uen: "GST 注册号: {{ uen }}", // GST Reg No.: {{ uen }}
      successfullyDelivered: "您的订单已于 {{ servingDate }} 成功送达", // Your order was successfully delivered on {{ servingDate }}
      identifier: "订单 #{{ identifier }}", // Order #{{ identifier }}
      orderStatusHeader: "订单状态", // Order status
      orderStatusFutureDateHeader: "订单状态跟踪将在 {{servingDate}} 可用", // Order status tracking will be available on {{servingDate}}
      noUpcomingOrders: "没有即将到来的订单", // No upcoming orders
      noPastOrders: "没有过去的订单", // No past orders
      track: {
        confirmed: "订单已确认。", // Order confirmed.
        preparing: "我们的厨房正在准备您的订单。", // Our kitchen is preparing your order.
        assigned: "已为您的订单分配了骑手。", // A rider has been assigned to your order.
        dispatched: "您的订单已离开我们的厨房。", // Your order has left our kitchen.
        ready_for_pickup: "您的订单已准备好在 {{labelForPickup}} 自取。", // Your order is ready to be picked up at {{labelForPickup}}.
      },
      deliveredBy: "由 {{logisticsName}} 送达", // Delivered by {{logisticsName}}
      subtotal: "小计:", // Subtotal:
      trackingLink: "跟踪您的订单", // Track your order
      deliveryFee: "外送费:", // Delivery fee:
      freeDelivery: "免费", // FREE
      tax: "GST:", // GST:
      serviceCharge: "服务费:", // Service charge:
      taxIncluded: "包含 GST:", // GST (included):
      surcharge: "附加费:", // Surcharge:
      discount: "折扣:", // Discount:
      donationAmount: "捐款", // Donation
      donationLabel: "{{donationLabel}} (捐款)", // {{donationLabel}} (Donation)
      totalPayable: "应付总额:", // Total payable:
      paidWithCard: "使用 {{cardBrandLabel}} 结尾为 {{cardLast4}} 的卡付款", // {{cardBrandLabel}} ending with {{cardLast4}}
      cardBrand: {
        mc: "万事达卡", // Mastercard
        visa: "维萨卡", // Visa
        amex: "美国运通卡", // AMEX
        jcb: "JCB", // JCB
        diners: "大莱卡", // Diners
        cup: "银联卡", // UnionPay
      },
      logistics: {
        asl_lalamove: "Lalamove", // Lalamove
        asl_milk_run: "MilkRun", // MilkRun
        asl_pandago: "Pandago", // Pandago
        asl_gogox: "GogoX", // GogoX
        lalamove: "Lalamove", // Lalamove
        milk_run: "MilkRun", // MilkRun
        pandago: "Pandago", // Pandago
        gogox: "GogoX", // GogoX
      },
    },
    paymentMethods: {
      header: "支付", // Payment
    },
    addresses: {
      header: "地址", // Addresses
      subheader: "选择您的默认外送地址或添加新地址。", // Select your default delivery address or add a new one.
      selectAddressHeader: "选择外卖地址", // Select delivery address
      selectOutletHeader: "选择门店", // Served by
      outletIsClosed: "在选定日期关闭", // Closed on selected date
      notWithinServingArea: "不在服务区内", // Not within serving area
      selectPickupPointHeader: "从门店自取", // Pickup from an outlet
      addAddressButton: "添加新地址", // Add new address
      removeAddressConfirmation: "您确定要删除此地址吗？", // Are you sure you want to remove this address?
      distanceFromAddress: "{{distance}}公里", // {{distance}}km
      nearestOutletServedBy: "门店：{{outletLabel}}", // Outlet: {{outletLabel}}
    },
    referrals: {
      copyReferralLink: "复制网址", // Copy link
      copied: "已复制！", // Copied!
      successfullyApplied: "推荐成功应用！您的奖励可以在促销代码页面中找到。", // Referral was applied successfully! Your rewards can be found in the promo codes page.
    },
    promoCodes: {
      header: "促销代码", // Promo codes
      description:
        "这些是您迄今为止收集的所有促销代码。确保在它们过期之前使用它们！", // These are all your promo codes you’ve collected to date. Make sure to use them before they expire!
      activeHeader: "有效的促销代码", // Active promo codes
      pastHeader: "过去的促销代码", // Past promo codes
      noActive: "您没有有效的促销代码。", // You have no active promo codes.
      noPast: "您没有过去的促销代码。", // You have no past promo codes.
      viewActive: "查看有效的促销代码", // View active promo codes
      viewPast: "查看过去的促销代码", // View past promo codes
      promoQuantity: "x{{quantity}}", // x{{quantity}}
      promoValidity: "有效期至{{formattedDate}}", // Valid until {{formattedDate}}
      viewPromoDetails: "查看详情", // View details
      used: "已使用", // Used
      expired: "已过期", // Expired
      details: {
        termsHeader: "条款和条件", // Terms and Conditions
        validityPeriod: "有效期至{{formattedDate}}。", // Valid till {{formattedDate}}.
        maxClaimsPerUser: "每个客户限制使用{{maxClaimsPerUser}}次。", // Limited to {{maxClaimsPerUser}} use(s) per customer.
        useNow: "现在使用", // Use now
        applied: "此促销代码已应用于您的购物车！", // This promo code has been applied to your cart!
        goToMenu: "继续菜单", // Continue to menu
      },
    },
  },
  addresses: {
    noAvailableOutlets: {
      header: "我们离你太远了", // We're too far from you
      copy: "哦，不，看来我们离你太远了。", // Oh no, it seems that we are located too far away from you.
      okay: "好的", // Okay
    },
  },
  menu: {
    loading: "加载菜单", // Loading menu
    noMenu: "找不到可用的菜单。", // No available menu found.
    sidebar: {
      header: "菜单", // Our Menu
    },
    deselected: {
      header: "不再提供", // No longer available
      body: "{{label}}在此位置不可用", // {{label}} is not available at this location
    },
    itemsRemoved: {
      proceed: "继续下单", // Proceed to order
      modalHeader: "您的购物车已更新", // Your cart has been updated
      modalContent:
        "由于您现在正在从{{outletName}}订购，所以已更新购物车的内容。", // The contents of your cart has been updated because you are now ordering from {{outletName}}.
      removedLineItem: "{{item}}已从您的购物车中移除", // {{item}} has been removed from your cart
    },
    cart: {
      cartHeader: "您的购物车", // Your cart
      checkoutHeader: "结账", // Checkout
      checkoutStages: {
        itemSummary: "项目摘要", // Item Summary
        details: "详细信息", // Details
        payment: "付款", // Payment
      },
      summary: "购物车摘要", // Cart Summary
      empty: "您的购物车是空的。", // Your cart is empty.
      edit: "配置产品", // Edit item
      editNotes: "编辑注释", // Edit notes
      showDetails: "显示详细信息", // Show details
      removeConfirmation: {
        copy: "从购物车中移除{{itemName}}？", // Remove {{itemName}} from cart?
        remove: "移除", // Remove
        cancel: "取消", // Cancel
      },
      cartItem: {
        soldOut: "售罄", // Sold out
        someItemsAreUnavailable: "只剩下{{count}}个", // Only {{count}} left
      },
      promotedProducts: {
        title: "也很搭配...", // Also goes well with...
      },
      footer: {
        amountToFreeDelivery: "再添加{{amount}}即可免费外送", // Add {{amount}} more for FREE delivery
        freeDelivery: "准备好摇滚了！", // Ready to rock and roll!
        labels: {
          minimumOrderValue:
            "您需要最低订购{{minimumOrderValue}}，再添加{{remainingValue}}即可结账。", // You need a minimum order of {{minimumOrderValue}}, add {{remainingValue}} more to checkout.
          amountToNextDeliveryFee:
            "再添加{{amount}}即可将外送费用降低到{{nextDeliveryFee}}", // {{amount}} more to reduce delivery fee to {{nextDeliveryFee}}
          checkout:
            "去结账 - <span id='go-to-checkout-amount'>{{amount}}</span>", // Go to checkout - <span id='go-to-checkout-amount'>{{amount}}</span>
          deliveryFee: "外送费", // Delivery fee
          outletServedBy: "门店：{{labelForPickup}}", // Outlet: {{labelForPickup}}
          pickup: "自取", // Pickup
          freeDelivery: "免费", // FREE
          switchToDelivery: "改成外送", // Switch to delivery
          switchToPickup: "改成自取", // Switch to pick up
          subtotal: "小计", // Subtotal
          surcharge: "附加费", // Surcharge
          discount: "折扣", // Discounts
          serviceCharge: "服务费", // Service charge
          tax: "消费税", // GST
          taxIncluded: "消费税（已包含）", // GST (Included)
          totalIncludingTax: "总计", // Total
        },
      },
      verifyAlcoholAgeModal: {
        header: "您已满18岁吗？", // Are you above 18 years old?
        body: "您的购物车中包含以下列出的酒精饮料，请确认您已满18岁以继续结账。", // Your cart contains alcoholic beverages listed below, please confirm that you are above 18 years of age to proceed with checkout.
        yesLabel: "是的，我已满18岁或以上，继续结账", // Yes, I am 18 years old or older, proceed to checkout
        noLabel: "不，我未满18岁，继续结账但不包括任何酒精饮料", // No, I am under 18 years old, proceed without any alcoholic beverages
      },
      checkout: {
        wordcount: {
          giftMessage: "剩余{{charactersLeft}}个字符", // {{charactersLeft}} characters left
        },
        labels: {
          serveOn: {
            delivery: "外送日期", // Deliver on
            pickup: "自取日期", // Collect on
            dine_in: "日期", // Collect on
          },
          tableName: "桌号：{{tableName}}", // Table: {{tableName}}
          change: "更改", // Change
          deliveryAddress: "外送地址", // Delivery address
          pickUpFrom: "自取地点", // Pick up from
          qrOutletLabel: "订购地点", // Ordering from
          isGift: "作为礼物订购", // Order as gift
          isGiftDescription: "我们将保密此订单的价格。您可以给收件人留言。", // We will keep the price of this order a secret. You can leave a message for your recipient.
          cutlery: "餐具", // Cutlery
          notes: {
            delivery: "外送说明", // Delivery instructions
            pickup: "自取说明", // Pickup instructions
            dine_in: "用餐说明", // Dine-in instructions
          },
          payment: {
            label: "付款方式", // Payment methods
            payment_intent: {
              label: "用信用卡支付", // Pay with credit card
              body: null,
            },
            pay_now: {
              label: "用 ", // Pay with
              body: "请向<span class='font-semibold'>{{uenOrMobile}}</span>转账，并将截图发送到<span class='font-semibold'>{{paymentContact}}</span>以帮助我们处理您的订单。", // Kindly PayNow transfer to <span class='font-semibold'>{{uenOrMobile}}</span> and send a screenshot to <span class='font-semibold'>{{paymentContact}}</span> to help us process your order.
            },
          },
          promoCode: "促销代码", // Promo code
          checkout: "下单 - <span id='place-order-amount'>{{amount}}</span>", // Place order - <span id='place-order-amount'>{{amount}}</span>
          minimumOrderValue: "最少订购{{minimumOrderValue}}", // Minimum order of {{minimumOrderValue}}
          contactName: "姓名", // Name
          contactNumber: "联系电话", // Contact number
          email: "电子邮件", // Email
          recipientName: "收件人姓名", // Recipient name
          recipientContactNumber: "收件人联系电话", // Recipient contact number
          giftMessage: "礼物留言", // Gift message
        },
        placeholders: {
          contactName: "我们该如何称呼您？", // How should we address you?
          contactNumber: "我们该联络谁？", // Who should we call?
          email: "我们该把订单确认发送到哪个电邮址？", // Where do we send the order confirmation to?
          notes: {
            delivery: "在外送过程中有什么我们应该注意的事项吗？", // Is there anything we should take note of during delivery?
            pickup: "在自取过程中有什么我们应该注意的事项吗？", // Is there anything we should take note of during pickup?
            dine_in: "有什么我们应该注意的事项吗？", // Is there anything we should take note of?
          },
          addressLine2: "楼层和单位号*", // Floor and unit no.*
          promoCode: "有促销代码吗？", // Got a promo code?
          recipientName: "我们应该如何称呼收件人？", // How should we address the recipient?
          recipientContactNumber: "收件人的联系方式是什么？", // What's the recipient's contact?
          giftMessage: "您想给收件人留下什么信息？", // What message would you like to give the recipient?
        },
        validations: {
          contactName: "需要联系人姓名", // Contact name required
          contactNumber: "需要联系电话", // Contact number required
          invalidContactNumber: "无效的联系电话", // Invalid contact number
          email: "电邮址格式不对", // Invalid email format
          addressLine2: "需要单位号", // Unit number required
          recipientName: "需要收件人姓名", // Recipient name required
          giftMessage: "需要礼物留言", // Gift message required
        },
        promoCodeModal: {
          header: "促销代码", // Promo code
          placeholder: "输入促销代码", // Enter a promo code
          apply: "应用", // Apply
        },
        stripeTotal: "总计", // Total
        offlinePaymentsConfirmation: "现在结账？付款的详细指示会显示在下一页。", // You will be shown details on how to make payment. Checkout?
        genericConfirmation: "您想继续结账吗？", // Would you like to proceed with checkout?
      },
    },
    menuSection: {
      empty: "此部分没有项目。", // There are no items in this section.
    },
    menuItem: {
      addToCart: "添加", // Add
      soldOut: "售罄", // Sold out
      preOrder: "预订", // Pre-order
      notAvailable: "不可用", // Not available
      limitedStock: "仅剩{{quantityLeft}}可用", // Only {{quantityLeft}} available
      menuNotAvailable: "选定的日期/时间无菜单可用。", // Menu not available for selected date/time.
    },
    productDetails: {
      labels: {
        ingredients: "成分", // Ingredients
        allergens: "过敏原信息", // Allergen information
        addToCart: "添加 (<span class='product-price'>{{price}}</span>)", // Add (<span class='product-price'>{{price}}</span>)
        update: "更新 (<span class='product-price'>{{price}}</span>)", // Update (<span class='product-price'>{{price}}</span>)
        preOrder: "预订 (<span class='product-price'>{{price}}</span>)", // Pre-order (<span class='product-price'>{{price}}</span>)
        notAvailable: "不可用", // Not available
        addToCartWithoutPrice: "添加", // Add
        updateWithoutPrice: "更新", // Update
        notes: "特别说明", // Special instructions
      },
      placeholders: {
        notes: "若您有特殊要求，我们会尽力满足。", // Let us know of any special requests, we will try our best to accommodate.
      },
      remove: "移除", // Remove
      removeConfirmation: "确认移除？", // Confirm remove?
      keep: "保留", // Keep
      modifierGroup: {
        selection: {
          included: "已包括", // Included
          required:
            '选择 $t(menu.productDetails.modifierGroup.selection.item, { "count": {{selectionRequiredMax}} }) ({{filled}}/{{selectionRequiredMax}})', // count": {{selectionRequiredMax}} }) ({{filled}}/{{selectionRequiredMax}})
          optional:
            "选择{{selectionRequiredMin}}至{{selectionRequiredMax}}个项目 ({{filled}}/{{selectionRequiredMax}})", // Select between {{selectionRequiredMin}} and {{selectionRequiredMax}} items ({{filled}}/{{selectionRequiredMax}})
          item: "{{count}} 个项目", // {{count}} item
          item_other: "{{count}} 个项目", // {{count}} items
        },
      },
      modifier: {
        selectedCount: "{{count}} ×", // {{count}} ×
        remove: "移除", // Remove
      },
    },
    nutriGrade: {
      alt: "营养等级 {{nutriGrade}}", // Nutri-Grade {{nutriGrade}}
      sugar: "糖", // sugar
      percent: "%", // %
      plus: "+", // +
    },
    preOrder: {
      header: "提前预订", // Order in advance
      body: "需要{{time}}的准备时间。", // This item has a lead time of {{time}}.
      selectEarliestDate: "预订{{date}}", // Order for {{date}}
      selectAnotherDate: "选择另一个日期或时间段", // Select another date or timeslot
      time: {
        days: "1天", // 1 day
        days_other: "{{count}} 天", // {{count}} days
        hours: "1小时", // 1 hour
        hours_other: "{{count}}小时", // {{count}} hours
        minutes: "1分钟", // 1 minute
        minutes_other: "{{count}}分钟", // {{count}} minutes
      },
    },
    availability: {
      header: "哎呀！", // Oops!
      selectAnotherDate: "选择另一个日期或时间段", // Select another date or timeslot
    },
    checkoutModal: {
      success: {
        header: "您的订单 #{{identifier}} 已确认", // Your order #{{identifier}} is confirmed
        dine_in:
          "订单确认已发送到您的收件箱。您的订单将在 <span class='font-semibold'>{{ servingDate }}</span>，在 <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span> 之间准备好自取。", // An order confirmation has been sent to your inbox. Your order will be ready for pick up on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.
        pickup:
          "订单确认已发送到您的收件箱。您的订单将在 <span class='font-semibold'>{{ servingDate }}</span>，在 <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span> 之间准备好自取。", // An order confirmation has been sent to your inbox. Your order will be ready for pick up on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.
        asap_pickup:
          "订单确认已发送到您的收件箱。您的订单将在 <span class='font-semibold whitespace-nowrap'>{{ minutesToPickup }}</span> 内准备好自取。", // An order confirmation has been sent to your inbox. Your order will be ready for pick up in <span class='font-semibold whitespace-nowrap'>{{ minutesToPickup }}</span>.
        delivery:
          "订单确认已发送到您的收件箱。您的订单将在 <span class='font-semibold'>{{ servingDate }}</span>，在 <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span> 之间送达。", // An order confirmation has been sent to your inbox. Your order will arrive on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.
        action: "返回主页", // Go back to main page
      },
      paymentDetails: {
        header: "待确认！", // Pending confirmation!
        beforeQrCode:
          "我们已收到您的订单 <span class='font-semibold'>#{{identifier}}</span>，但确认尚待进行。<br/><br/>请通过 PayNow 转账 <span class='font-semibold'>{{price}}</span> 至 <span class='font-semibold'>{{uenOrMobile}}</span>，并将截图发送至 <span class='font-semibold'>{{paymentMobileNumber}}</span>，以帮助我们处理您的订单。", // We have received your order <span class='font-semibold'>#{{identifier}}</span> but confirmation is pending.<br/><br/>Kindly PayNow transfer <span class='font-semibold'>{{price}}</span> to <span class='font-semibold'>{{uenOrMobile}}</span> and send a screenshot to <span class='font-semibold'>{{paymentMobileNumber}}</span> to help us process your order.
        mobileNotes: {
          header: "手机用户", // For mobile users
          body: "要通过 PayNow 转账，请截图 QR 码并在任何支持 PayNow 的银行应用中使用。", // To transfer via PayNow, please screenshot the QR code and use it within any PayNow enabled banking application.
        },
        afterQrCode: "一旦我们确认了您的付款，您将收到订单确认邮件。", // You will receive an order confirmation email once we have confirmed your payment.
        action: "好的，我已转账", // Okay, I have made the transfer
      },
      processing: {
        header: "您的订单正在处理中", // Your order is being processed
        description:
          "这可能需要几秒钟到几分钟的时间。<br/>一旦我们完成了您的订单处理，我们将向您发送确认邮件。<br/>您也可以留在此页面等待处理完成。", // This can take anywhere from a few seconds to a few minutes.<br/>We will send you an email confirmation once we've completed processing your order.<br/>You can also stay on this page to wait for the process to be completed.
      },
      thankYou: "谢谢", // Thank you
    },
    pathname: "菜单", // Menu
  },
  stripe: {
    error: "处理您的付款时发生错误。", // An error occurred while processing your payment.
  },
  adyen: {
    labels: {
      header: "输入您的付款详情", // Enter your payment details
      card: "信用卡/借记卡", // Credit/Debit card
    },
    actions: {
      tryAgain: "再试一次", // Try again
    },
    errors: {
      generic: "请检查卡号、过期日期和安全码是否正确。", // Please check that the card number, expiration, and security code is correct.
    },
    payNowModal: {
      header: "请在3分钟内付款并返回此页面", // Please pay within 3 minutes and return to this page
      instruction1: "截图二维码", // Take a screenshot of the QR code
      instruction2: "使用您的PayNow应用并加载图像", // Use your PayNow app and load the image
      instruction3: "付款后返回此页面", // Return to this page after payment
      instructionWarning:
        "请在<b>3分钟内</b>付款并<b>返回此页面</b>，以确保订单将被确认。", // Your order will not be confirmed if you do not pay <b>within 3 minutes</b> and <b>return to this page</b>.
      acknowledge: "我明白", // I understand
    },
  },
  faq: {
    header: "常见问题", // Frequently asked questions
    content: {
      question1: "我如何知道我的订单是否已确认？", // How do I know whether my order is confirmed?
      answer1: "您会收到订单确认电邮。", // You will find an order confirmation in your email inbox.
      question2: "我可以替朋友下订单吗？", // Can I place an order for a friend?
      answer2:
        "当然可以！只需选择您朋友的外送地址，将他/她喜欢的菜品加入购物车，并在特殊说明中写上姓名和联系电话。", // Of course! Just select your friend's delivery address, add his/her favourite dishes to cart, and include the name and contact number in the special instructions.
      question3: "我如何检查我的订单的外送状态？", // How do I check the delivery status of my order?
      answer3:
        "您可以在您的帐户中检查外送状态 — 点击订单，然后收据。外送状态只在外送日期当天可用。", // You can check the delivery status in your account — click on orders, then receipt. The delivery status is only available on the serving date.
      question4: "我的餐中有什么？", // What is in my meal?
      answer4:
        "我们希望您能确切地知道我们准备的每道菜中都有什么，所以您可以通过点击菜品的图片来查看所有的成分以及主要的过敏源。", // We want you to know exactly what goes into every dish we prepare, so you can view the full list of ingredients as well as major allergens by clicking on the picture of the dish. (We can’t list “love” though).
      question5: "我的餐可以保存多久？", // How long will my meal keep for?
      answer5: "我们每天都会准备新鲜的餐点，所以最好在外送后的2-3小时内食用。", // We prepare our meals fresh every day, so they are best consumed within 2-3 hours of delivery.
      question6: "如果我在快递员到达时不在场怎么办？", // What happens if I am not around when the courier arrives with my meal?
      answer6:
        "我们会在到达时给您打电话或发短信，如果您希望我们把餐点留在您的门口，请告诉我们。", // We will call or text you when we arrive, let us know if you would like us to leave the meal at your door.
      question7: "我的信用卡信息安全吗？", // Is my credit card information safe?
      answer7:
        "绝对安全。我们不会在我们的服务器上存储任何敏感信息。我们使用Stripe作为我们的付款处理器。Stripe已经通过PCI认证的审计，并获得了PCI服务提供商1级的认证。这是支付行业中最严格的认证级别。", // Absolutely. We do not store any of this sensitive information on our servers. We use Stripe as our payment processor. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.
    },
  },
  timeslotPicker: {
    header: "选择日期和时间段", // Select a date and timeslot
    noTimeslotSelected: "请选择一个时间段", // Please select a timeslot
    selectedTimeslotLabel: "{{cartDate}}, {{rangeLabel}}", // {{cartDate}}, {{rangeLabel}}
    fulfilmentTypeUnavailable: "选定的时间段不适用于{{fulfilmentType}}", // Selected timeslot is not available for {{fulfilmentType}}
    noTimeslots: "没有可用的时间段。", // No timeslots available.
    orderValueAdditionalLeadTimeWarning:
      "订单金额超过{{orderValue}}将需要额外的{{additionalLeadTime}}准备时间", // Orders above {{orderValue}} will need additional lead time of {{additionalLeadTime}}
    closed: "已关闭", // Closed
    typeLabel: {
      asap: "尽快", // As soon as possible
      breakfast: "早餐", // Breakfast
      brunch: "早午餐", // Brunch
      breakfast_and_lunch: "早餐和午餐", // Breakfast & Lunch
      lunch: "午餐", // Lunch
      off_peak: "非高峰", // Off-Peak
      offPeak: "非高峰", // Off-Peak
      tea_time: "下午茶", // Tea-time
      happyHour: "快乐时光", // Happy Hour
      dinner: "晚餐", // Dinner
      supper: "夜宵", // Supper
      available_timeslots: "可用时间段", // Available timeslots
      preorders: "预订单", // Pre-orders
    },
  },
  addressPicker: {
    header: "外送地址", // Where should we deliver to?
    placeholder: "输入路名或邮政编码", // Enter a street name or postal code
    noResults: "无结果", // No results
    okText: "确认地址", // Confirm address
    confirm: "确认", // Confirm
    cancel: "取消", // Cancel
    error: {
      noPostalCode: "哎呀，我们找不到这个地址的邮政编码。试试用邮政编码搜索？", // Oops, we can't find the postal code for this address. Try searching by postal code instead?
    },
    outletIsClosedToday: "您选择的门店今天已关闭。尝试选择其他门店或日期？", // Your selected outlet is closed today. Try a different outlet or date?
    outletIsClosed: "您选择的门店在选定的日期已关闭。尝试选择其他门店或日期？", // Your selected outlet is closed on chosen date. Try a different outlet or date?
  },
  outletSelector: {
    header: "自取地点", // Where do you want to pick up from?
  },
  fulfilmentTypes: {
    delivery: "外送", // Delivery
    pickup: "自取", // Pickup
    dine_in: "内用", // Dine-in
  },
  multiBrand: {
    ourConcepts: "我们的概念", // Our concepts
  },
  login: {
    header: "欢迎回来", // Welcome back
    subheader: "登录到您的帐户", // Log in to your account
    description: "登入账户，以便更快的结账，追踪订单以及享用独家优惠。", // Log in with your email for faster checkouts, order tracking and exclusive deals for you and your friends.
    descriptionBeforeSignupLink: "还没注册帐户？", // No account yet?
    signupAction: "在此注册", // Sign up here
    referralHeader:
      "欢迎回来，<br class='hidden sm:block' />{{referrerName}}已经告诉我们你会来", // Welcome back, <br class='hidden sm:block' />{{referrerName}} told us you'd be coming
    referralSubheader1: "登录并在您的首次订单中获得{{rewardValue}}的折扣。", // Log in and get {{rewardValue}} off your first order.
    referralSubheader2: "还没注册帐户？", // Don't have an account?
    referralSubheader3: "注册", // Sign up
    emailLabel: "电邮址", // Email address
    emailPlaceholder: "输入您的电邮址", // Enter your email
    passwordLabel: "密码", // Password
    facebookLoginButton: "使用Facebook登录", // Continue with Facebook
    googleLoginButton: "使用Google登录", // Continue with Google
    otpButton: "登录", // Log in
    useEmailInput: "使用电邮址登录", // Continue with email
    useMobileNumberInput: "使用手机号码登录", // Continue with phone
    whatIsAtlas: "什么是Atlas？", // What is Atlas?
    error: {
      emailNotFound: "电邮址尚未注册。", // Email is not registered yet.
      mobileNumberNotFound: "手机号码尚未注册。", // Mobile number is not registered yet.
      EMAIL_TAKEN: "<strong>{{email}}</strong>已经注册了一个现有的帐户。", // <strong>{{email}}</strong> has an existing account registered.
      MOBILE_NUMBER_TAKEN:
        "<strong>{{mobileNumber}}</strong>已经注册了一个现有的帐户。", // <strong>{{mobileNumber}}</strong> has an existing account registered.
    },
  },
  signup: {
    header: "立即开始", // Get started in no time
    description: "在30秒内轻易注册帐户。", // Sign up for an account in 30 seconds. Easy.
    descriptionBeforeLoginLink: "已经有帐户了？", // Already have an account?
    loginAction: "在此处登录", // Log in here
    signupAndCheckout: "注册并结账", // Sign up and checkout
    skipSignup: "不注册，作为访客直接结账", // Skip to checkout as a guest
    referralHeader:
      "嘿，<br class='hidden sm:block' />{{referrerName}}已经告诉我们你会来", // Hey there, <br class='hidden sm:block' />{{referrerName}} told us you'd be coming
    referralSubheader1: "注册并在您的首次订单中获得{{rewardValue}}的折扣。", // Sign up and get {{rewardValue}} off your first order.
    referralSubheader2: "已经有帐户了？", // Already have an account?
    referralSubheader3: "登录。", // Log in.
    titleLabel: "称呼", // Title
    titlePlaceholder: "我们应该如何称呼您？", // How should we address you?
    nameLabel: "名称", // Name
    namePlaceholder: "您的全名是什么？", // What is your full name?
    mobilePlaceholder: "联系电话", // Contact number
    dobLabel: "出生日期", // Date of birth
    dobPlaceholder: "您的生日是什么时候？", // When is your birthday?
    marketingConsentLabel:
      "我同意通过电子邮件接收独家折扣，季节性特价和最新更新。", // I consent to receiving exclusive discounts, seasonal specials, and latest updates via email.
    privacyPolicyTitle: "隐私权政策", // Privacy Policy
    termsOfServiceTitle: "服务协议", // Terms of Service
    privacyJoinCopy: "及", // and
    privacyPolicyLineEnding: "和", // and
    privacyAgreement:
      "继续，您同意{{ partnerPolicy }}Atlas的<a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Terms-of-Use-dedd931f781f4d609ce784461f245690'>服务协议</a>及<a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Privacy-Policy-c256dd2a5fa64f1eb903d03b04f6ea3d'>隐私权政策</a>。", // By continuing, you agree to the {{ partnerPolicy }} Atlas <a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Terms-of-Use-dedd931f781f4d609ce784461f245690'>Terms of Service</a> and <a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Privacy-Policy-c256dd2a5fa64f1eb903d03b04f6ea3d'>Privacy Policy</a>.
    signupButton: "注册帐户", // Sign up for an account
    facebookSignupButton: "使用Facebook注册", // Sign up with Facebook
    googleSignupButton: "使用Google注册", // Sign up with Google
    otpButton: "注册", // Sign up
    signupReferralCodePlaceHhlder: "注册推荐码", // Signup referral code
    whatIsAtlas: "什么是Atlas？", // What is Atlas?
    error: {
      titleRequired: "需要称呼", // Title is required
      nameRequired: "需要名称", // Name is required
      emailRequired: "需要电邮址", // Email is required
      invalidEmail: "无效的电邮址", // Invalid email address
      invalidPassword: "密码必须包含至少八个字符，其中包含数字和字母的组合", // Password must contain a minimum of eight characters with a combination of numbers and letters
      passwordsNotMatch: "密码不匹配", // Passwords do not match
      mobileNumberRequired: "需要手机号码", // Mobile number is required
      invalidMobileNumber: "这不是有效的手机号码", // This is not a valid mobile number
    },
    options: {
      title: {
        mr: "Mr",
        miss: "Miss",
        mrs: "Mrs",
        mx: "Mx",
      },
    },
  },
  otpModal: {
    otpChannels: {
      sms: "短信", // SMS
      email: "电邮", // email
    },
    title_login: "使用6位数代码登录", // Log in with 6-digit code
    subTitle_login:
      "输入发送到您的{{deliveryMode}}的代码以登录到您的{{channelLabel}}帐户。", // Enter the code sent to your {{deliveryMode}} to log in to your {{channelLabel}} account.
    title_signUp: "使用6位数代码注册", // Sign up with 6-digit code
    subTitle_signUp:
      "输入发送到{{deliveryMode}}的代码以注册您的{{channelLabel}}帐户。", // Enter the code sent to {{deliveryMode}} to sign up to your {{channelLabel}} account.
    title_verify: "使用6位数代码验证", // Verify with 6-digit code
    subTitle_verify: "输入发送到{{deliveryMode}}的代码以验证您的电话号码。", // Enter the code sent to {{deliveryMode}} to verify your phone number.
    title_signUpWithAtlasId: "使用您的Atlas ID注册", // Sign up with your Atlas ID
    subTitle_signUpWithAtlasId:
      "您已有Atlas ID。输入发送到您的{{deliveryMode}}的代码以在{{channelLabel}}上使用您的Atlas ID。", // You have an existing Atlas ID. Enter the code sent to your {{deliveryMode}} to use your Atlas ID on {{channelLabel}}.
    codeSentToEmail: "代码发送到{{email}}", // Code sent to {{email}}
    codeSentToSms: "代码发送到电话{{phone}}", // Code sent to phone {{phone}}
    codeResentToEmail: "代码重新发送到{{email}}", // Code resent to {{email}}
    codeResentToSms: "代码重新发送到电话{{phone}}", // Code resent to phone {{phone}}
    sendToEmail: "发送到{{email}}", // send to {{email}}
    sendToSms: "发送到电话{{phone}}", // send to phone {{phone}}
    resendToEmail: "重新发送", // Resend
    resendToSms: "重新发送", // Resend
    or: " 或 ", //  or
    delayMessage: "请等待30秒再重新发送。", // Please wait 30 seconds to resend again.
    successMessage: "验证成功。正在将您重定向到下一步。", // Verification successful. Redirecting you to the next step.
  },
  footer: {
    sitemap: {
      home: "首页", // Home
      menu: "菜单", // Menu
      customerReviews: "客户评论", // Customer Reviews
      faqs: "常见问题解答", // FAQs
    },
    socialLinks: {
      label: "社交媒体", // Social Media
      facebook: "脸书", // Facebook
      instagram: "Instagram", // Instagram
      twitter: "推特", // Twitter
    },
    legal: {
      label: "法律", // Legal
      cookiesPolicy: "Cookie政策", // Cookies Policy
      documentation: "文档", // Documentation
      privacyPolicy: "隐私政策", // Privacy Policy
    },
    contactInfo: {
      label: "联系我们", // Contact Us
    },
  },
  pages: {
    contactForm: {
      labels: {
        name: "姓名", // Name
        email: "电邮址", // Email
        contactNumber: "联系电话", // Contact number
        subject: "主题", // Subject
        message: "信息", // Message
      },
      submitButtonTitle: "提交", // Submit
      submitSuccessMessage: "表单提交成功。", // Form submitted successfully.
    },
    nutritionCalculator: {
      resultsHeader: "结果", // Results
      macrosHeader: "营养资料", // Nutrition Facts
      selectedHeader: "已选", // Selected
      resetButtonTitle: "重置", // Reset
      macros: {
        label: {
          calories: "卡路里", // Calories
          protein: "蛋白质", // Protein
          carbs: "碳水化合物", // Carbs
          fat: "脂肪", // Fat
        },
        suffix: {
          calories: "", // "
          protein: "克", // g
          carbs: "克", // g
          fat: "克", // g
        },
      },
    },
  },
  reloadPrompt: {
    optional: {
      title: "有新的更新可用", // New update available
      subTitle: "为了确保最佳体验，请刷新此页面。", // To ensure the best experience, please refresh this page.
      okText: "重新加载", // Reload
      cancelText: "取消", // Dismiss
    },
    mandatory: {
      title: "需要更新", // Update required
      subTitle: "为了确保应用程序顺利运行，请立即更新。", // To ensure that the application runs smoothly, please update now.
      okText: "重新加载", // Reload
    },
  },
  promotedProductsModal: {
    title: "添加更多菜品？", // Would you like to complement your meal with these items?
    cancelButton: "不，谢谢", // No, thank you
    okButton: "查看购物车 - {{amount}}", // View cart - {{amount}}
  },
};
